.artistEventsContainer {
    min-height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.searchContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media (min-width: 768px) {
    .searchContainer {
        display: none;
    }
}
