.input-group {
    margin-top: 10px;
  }

.input-group .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group .btn-outline-secondary {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
