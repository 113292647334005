.paginationComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin: 20px 0;
    width: 100%;
}

.paginationButton {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    min-width: 2.5rem;
}

@media (max-width: 576px) {
    .paginationButton {
        padding: 0.2rem 0.4rem;
        font-size: 0.75rem;
        min-width: 2rem;
    }

    .perPageButton {
        margin-left: 0;
        margin-top: 0.5rem;
    }
}
