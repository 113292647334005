.heroSection {
    color: black;
    height: 100vh;
    overflow: hidden;
}

.parallax {
  height: 100vh;
  /* Centering content */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Stack elements vertically */
  /* Background image for the parallax effect */
  background-image: url("/public/images/coolBackground1.jpg");
  background-attachment: scroll;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  overflow: hidden;
}

.headerContainer {
    position: relative;
    z-index: 2;
    height: 150px; /* Set a fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes twinkleAway {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.spinnerTwinkle {
  animation: twinkleAway 0.5s ease-in-out forwards;
}

.headerFadeIn {
  animation: fadeIn 1s ease-in-out forwards;
  opacity: 0; /* Initially hidden */
}

@media (max-width: 768px) {
    .parallax {
        background-attachment: scroll; /* Improve performance on mobile */
    }

    .headerContainer {
        height: 100px; /* Adjust height for mobile */
    }
}
