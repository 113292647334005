/* Navbar sticky */
.stickyNavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure it's above other elements */
}

nav li {
    display: inline-block;
    margin-left: 70px;
    padding-top: 23px;

    position: relative;
}

nav a {
    color: #444;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
}

nav a::before {
    content: '';
    display: block;
    height: 5px;
    background-color: #0db10f;
    position: absolute;
    top: 0;
    width: 0%;
    transition: all ease-in-out 250ms;
}

nav a:hover::before, nav a.active::before {
    width: 100%;
}

@media (max-width: 768px) {
    .searchContainer {
        display: none;
    }
}
