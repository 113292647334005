.card {
    border: none; /* Remove border if you want a cleaner look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
    transition: transform 0.2s ease; /* Smooth hover effect */
  }

.card:hover {
    transform: translateY(-5px); /* Lift card on hover */
}

.cardTitle {
    font-weight: bold;
    font-size: 1.25rem;
}

.btnPrimary {
    background-color: #fff;
    background-image: linear-gradient(225deg, #136a8a 0%, #722678 100%);
    color: #fff;
    border-color: #680dfd;
}
